<template>
  <div class="weather-list">
    <div class="img-wrap" v-if="weatherData.length == 0">
      <img :src="nodata" class="nodata-img">
      <div>暂无数据</div>
    </div>
    <div v-else>
      <div class="title">最近6天 精简版天气预报</div>
      <div class="weather-wrap" v-for="(item, index) in weatherData" :key="index">
        <div class="weather flex justify_content_space_between" :style="{backgroundImage: 'url(' + item.backUrl + ')',backgroundSize: '100% 100%'}">
          <div class="flex align_items_center weather_temp">
            <div class="weather-num flex">
              <div class="blod">{{item.tempDay}}</div>
              <div>℃</div>
            </div>
            <div class="weather_info">
              <div class="flex align_items_center weather_info_text">{{localCity}}</div>
              <div class="flex align_items_center weather_info_text">{{item.predictDate | dateFomatter}}</div>
            </div>
          </div>
          <div class="height_full flex weather_icon">
            <div class="weather_icon_image" :style="{backgroundImage: 'url(' + item.iconUrl + ')',backgroundSize: '100% 100%'}"></div>
            <div class="weather_icon_text">{{item.conditionDay}}</div>
          </div>
        </div>
      </div>
    </div>
    
    
  </div>
</template>

<script>
import { Divider  } from 'vant'
import { getCondition6Days } from '@/utils/api'
import {uwStatisticAction} from "../../utils/util";
export default {
  components:{
    [Divider.name]: Divider,
  },
  filters:{
    dateFomatter(val){
      var d = new Date(val)
      var day = d.getDate() < 10 ? '0' + d.getDate() : '' + d.getDate()
      var months = new Array("一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月");
      var month = months[d.getMonth()]
      var weeks = new Array("周日", "周一", "周二", "周三", "周四", "周五", "周六");
      var weekDay = d.getDay();
      var week = weeks[weekDay];
      return day + '  ' + month + '  ' + week
    }
  },
  data () {
    return {
      weatherData: [],
      localCity: '',
      nodata: require('@/assets/img/nodata/nodata.png'),
    }
  },
  mounted () {
    this.localCity = this.$route.query.city ? this.$route.query.city : '北京市'
    //this.toGetCondition()
    uwStatisticAction('/weatherList','天气列表')
  },
  methods: {
    toGetCondition () {
      getCondition6Days({
        'city': this.localCity
      }).then(res => {
        if (res.code === 200) {
          if (res.data.businessCode && res.data.businessCode === 200) {
            this.weatherData = res.data.data.forecast
          }
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>
.weather-list {
  background: #F7F8F9;
  height: 100%;
  min-height: 100%;
  .title{
    color: #9B9B9B;
    font-size: 0.28rem;
    text-align: center;
    padding: 0.22rem 0 0.2rem;
  }
  .img-wrap{
    text-align: center;
    padding: 1rem 0 0;
    div{
      font-size: 0.26rem;
      color: #9B9B9B;
    }
  }
  .nodata-img{
    width:4rem;
  }
  .weather-wrap {
    padding: 0.12rem 0.3rem 0.12rem;
  }
  .weather-num{
    width: 1.3rem;
    justify-content: center;
  }
  .weather {
    height: 1.6rem;
    color: white;
    border-radius: 0.16rem;
    .weather_temp {
      margin-left: 25px;
      font-size: 0.6rem;
    }
    .weather_info {
      margin-left: 0.6rem;
      .weather_info_text {
        height: 19px;
        font-size: 0.24rem;
      }
    }
    .weather_icon {
      flex-direction: column;
      justify-content: center;
      margin-right: 42px;
      .weather_icon_image {
        height: 34px;
        width: 34px;
        margin: 0px auto 5px auto;
      }
      .weather_icon_text {
        font-size: 11px;
        font-weight: 200;
        margin: 0px auto;
      }
    }
  }
}
</style>